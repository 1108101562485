import { FC, ReactElement, useEffect, useState } from "react";

const Header: FC<{activeElement: Element[]}> = ({activeElement}): ReactElement => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [mobileWidth, setMobileWidth] = useState<boolean>(false);

  useEffect(() => {
    const getMobileWidth = (): void => {
      setMobileWidth(window.innerWidth <= 800)
    }
    getMobileWidth();

    window.addEventListener('resize', getMobileWidth, false);

    return () => {
      window.removeEventListener('resize', getMobileWidth, false);
    }
  }, [])

  const handleMenuOpen = (): void => {
    if (!menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    setMenuOpen(old => !old)
  }

  const desktopMenu: ReactElement = (
    <div className="boxed_nav">
      <div>
        <img
          src="/logo-10.png"
          alt="Magyar Szülésznők Egyesülete"
          style={{objectFit: 'contain', width: '100%', height: '100%'}}
        />
      </div>
      <ul>
        <li><a href="#index" className={activeElement.some(el => el.id === 'index') ? 'highlight' : ''}>Kezdőlap</a></li>
        <li><a href="#about_us" className={activeElement.some(el => el.id === 'about_us') ? 'highlight' : ''}>Rólunk</a></li>
        <li><a href="#events" className={activeElement.some(el => el.id === 'events') ? 'highlight' : ''}>Rendezvényeink</a></li>
        <li><a href="#awards" className={activeElement.some(el => el.id === 'awards') ? 'highlight' : ''}>Díjaink</a></li>
        <li><a href="#activity" className={activeElement.some(el => el.id === 'activity') ? 'highlight' : ''}>Tevékenységeink</a></li>
        <li><a href="#international" className={activeElement.some(el => el.id === 'international') ? 'highlight' : ''}>Nemzetközi kapcsolatok</a></li>
        <li><a href="#press" className={activeElement.some(el => el.id === 'press') ? 'highlight' : ''}>Megjelenéseink</a></li>
        <li><a href="#contact" className={activeElement.some(el => el.id === 'contact') ? 'highlight' : ''}>Kapcsolat</a></li>
      </ul>
    </div>
  )

  const mobileMenu: ReactElement = (
    <div className="boxed_nav">
      <div style={{height: '6rem', overflow: 'hidden', justifySelf: 'flex-start'}}>
        <img
          src="/logo-10.png"
          alt="Magyar Szülésznők Egyesülete"
          style={{objectFit: 'contain', width: '100%', height: '100%'}}
        />
      </div>
      <div className="hamb_menu" onClick={handleMenuOpen}>
        <span></span><span></span><span></span>
      </div>
      {menuOpen ?
        <div className="mobile_menu">
          <ul>
            <li><a href="#index"  onClick={handleMenuOpen} className={activeElement.some(el => el.id === 'index') ? 'highlight' : ''}>Kezdőlap</a></li>
            <li><a href="#about_us" onClick={handleMenuOpen} className={activeElement.some(el => el.id === 'about_us') ? 'highlight' : ''}>Rólunk</a></li>
            <li><a href="#events" onClick={handleMenuOpen}  className={activeElement.some(el => el.id === 'events') ? 'highlight' : ''}>Rendezvényeink</a></li>
            <li><a href="#awards" onClick={handleMenuOpen}  className={activeElement.some(el => el.id === 'awards') ? 'highlight' : ''}>Díjaink</a></li>
            <li><a href="#activity" onClick={handleMenuOpen}  className={activeElement.some(el => el.id === 'activity') ? 'highlight' : ''}>Tevékenységeink</a></li>
            <li><a href="#international" onClick={handleMenuOpen}  className={activeElement.some(el => el.id === 'international') ? 'highlight' : ''}>Nemzetközi kapcsolatok</a></li>
            <li><a href="#press" onClick={handleMenuOpen}  className={activeElement.some(el => el.id === 'press') ? 'highlight' : ''}>Megjelenéseink</a></li>
            <li><a href="#contact" onClick={handleMenuOpen}  className={activeElement.some(el => el.id === 'contact') ? 'highlight' : ''}>Kapcsolat</a></li>
          </ul>
        </div>
        :
        null
      }
    </div>
  )

  return (
    <nav>{mobileWidth ? mobileMenu : desktopMenu}</nav>
  )
}

export default Header;