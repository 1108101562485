import { Dispatch, FC, ReactElement, SetStateAction, useEffect, useRef, useState } from "react";

const Activities: FC<{setActiveElement: Dispatch<SetStateAction<Element[]>>}> = ({setActiveElement}): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [height, setHeight] = useState<number | undefined>(0);
  const par = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;

    const observer = new IntersectionObserver(([entry]) => {
      entry.isIntersecting ?
        setActiveElement(old => [...old, entry.target])
        :
        setActiveElement(old => old.filter(v => v.id !== entry.target.id))
    }, {rootMargin: '-50%'})

    element && observer.observe(element);

    return () => {
      element && observer.unobserve(element);
    }
  }, [setActiveElement])

  useEffect(() => {
    const getHeight = () => {
      setHeight(par.current ? par.current.clientHeight + 40 : 0);
    }
    getHeight();

    window.addEventListener('resize', getHeight, false);

    return () => {
      window.removeEventListener('resize', getHeight, false);
    }
  }, [])

  return (
    <div className="container" id="activity" ref={ref}>
      <h1>Tevékenységeink</h1>
      <div style={{position: 'relative', padding: '2rem 3rem', backgroundColor: '#f7ebf0', marginBottom: isOpen ? "2rem": "0"}}>
        {!isOpen ?
          <div style={{position: 'absolute', inset: 0, background: 'linear-gradient(transparent, white)', zIndex: 9}}></div>
          :
          null
        }
        <p style={{color: "#E5B8B8"}}>Magyar Szülésznők Egyesülete -ICM-DR Ukraine Project</p>
        <h2 style={{marginTop: '1rem'}}>Ukrajnából menekültek támogatása</h2>
        <p>
          A projekt teljes egészében a Direct Relief – ICM támogatás alapján valósult meg. 2022.szeptember 1. és október 14. között 2 menekülttábornak összesen 5 alkalommal tudtunk segítséget nyújtani. Kutatómunkánk után, 2 támogatásra szoruló menekülttábort találtunk. Fontos volt számunkra, hogy földrajzilag messze legyenek egymástól, hogy szélesebb spektrumban tudjunk segíteni. Így Záhony illetve Budapest városban működő tábort kerestünk fel.
          Kiderült, hogy Magyarországot leginkább tranzit országnak tekintik, mint a hazájukhoz legközelebbi. Itt várják meg amíg elkészülnek a hivatalos papírjaik, majd utaznak tovább. Nyílván addig napok telnek el. Nem ritka hogy több gyermekkel, és egy táskával érkeznek a szállásra. Gyakran 150-200 főt kell elszállásolniuk. Támogatást a fenntartáshoz leginkább civil szervezetektől kapnak.  De ahogy az idő telik az adakozási kedv is alábbhagy.
          Projekt ideje alatt az alább felsorolt termékcsaládokat vásároltuk meg és szállítottuk ki a menekülttáboroknak, előzetes igényfelmérést követően.
        </p>
        <div style={{height: isOpen ? `${height}px` : 0, transition: 'all 0.25s ease-in-out', overflow: 'hidden' }}>
          <div ref={par} style={{margin: 0}}>
            <ul>
              <li>Élelmiszerek</li>
              <li>Higiénés termékek</li>
              <li>Bébiételek</li>
              <li>Italok és üdítők</li>
              <li>Gyógyszerek</li>
              <li>Vitaminok</li>
              <li>Takarók</li>
            </ul>
            <h2 style={{marginTop: '3rem', marginBottom: '2rem'}}>Záhony</h2>
            <p>
              Záhony város a Magyar-Szlovák-Ukrán hármashatárnál helyezkedik el. Ukrajna felől érkezve a legelső település. Az Ukrajna - Oroszország háború kezdete óta, nagy erőkkel dolgoznak, hiszen a legelső állomás a menekülteknek, így -főként a kezdeti időkben özönlöttek a menekültek a városba, illetve a menekülttáborba. 
            </p>
            <p>
              A menekülttábor vezetője egyben a város polgármester is. Helmeczy László. A munkáján kívül minden idejét a táborban, illetve az ahhoz kapcsolódó tennivalókkal tölti. A menekülttábor látja el a vonatállomásra érkező a háború elől menekülők szükség esetén fordítási, illetve engedélyek, igazolványok beszerzéssel kapcsolatos ügyintézéseket. 
            </p>
            <p>
              A vasútállomáson étellel, itallal várják a háború elől menekülőket. Ottjártunkkor erre a célra mi is készítettünk útravaló csomagokat a gyermekeknek.  
            </p>
            <h2 style={{marginTop: '3rem', marginBottom: '2rem'}}>
              A tábor
            </h2>
            <p>
              A tábor 120 embernek nyújt kényelmes szállást. Mindennel felszerelt. Hálóhelyiségek, Fürdőszobák,Mosógép, teakonyha, gyermekeknek játszószoba, gyengélkedő, raktár. Az emberek leggyakrabban 1, maximum 2 éjszakát tartózkodnak itt, majd tovább utaznak amint az utazáshoz megfelelő papírjaik elkészültek. Első ottjártunkkor (szeptember 7.) azt mondták, egyre kevesebb menekült érkezik, viszont utolsó ottjártunkkor (október 14.) szinte tele volt a szállás, főként anyákkal és gyermekeikkel. Elmondták, hogy Kijev városát elkezdték bombázni, így úgy döntöttek ők is, hogy nincs mire várniuk, el kell hagyni a hazáját, mert a gyermekei biztonsága mindennél fontosabb. 
            </p>
            <p>
              Előzetes igényfelmérésünk alapján nekik élelmiszerekre, üdítőitalokra és édességekre volt legnagyobb szükségük. Illetve a konyhára minden alapélelmiszer, amiből meleg ételt tudnak főzni az érkezőknek. 
            </p>
            <p>
              Beszélgettem a dolgozókkal, menekültekkel egyaránt miközben bemutatták nekünk a tábort. Szívet tépő sorsokat hallgattam végig. A dolgozók részéről pedig embert próbáló hétköznapokról, helyzetekről hallgathattam történeteket. Egyikük elmondta, hogy pár hónapja egy Nő úgy menekült a háború elől kisgyermekével, hogy a vajúdás elkezdődött nála, amire Záhony városába érkezett, már szülés közelében volt. Leszállt a vonatról és ott a vasúti sínek között adott életet gyermekének az ő laikus segítségükkel. Mire a mentőszolgálat a helyszínre érkezett a szülés lezajlott. Kisfiú született. 
            </p>
            <h2 style={{marginTop: '3rem', marginBottom: '2rem'}}>Budapest</h2> 
            <p>
              Az Ukrajnából menekülők egyik első állomása, ahol nyugodtabb körülmények között megpihenhetnek, a Migration Aid Madridi úti menekültszállója.
              Március 12-én nyitották meg a szállót, max. 300 férőhelyes, elsősorban nőknek és gyermekeknek tartjuk fenn. A menekülők többsége csak 1-2 éjszakát tölt itt, de egyre többen vannak, akik Európán kívüli vízumra várva heteket töltenek nálunk.
              A menekültszállót folyamatosan fejlesztik a beérkező támogatásokból: saját játszósarka, egészségügyi szobája, a menekültek számára ingyenes miniboltja van, a teljes ellátást is adományokból tudják megoldani.
              A menekültszálló sajátossága, hogy Magyarország legnagyobb, kizárólag önkéntesek által működtett és adományokból finanszírozott ilyen jellegű intézménye.
              Már több mint 2000 önkéntes fordult meg a Madridin az elmúlt 3 hónapban. Napi 40-50 lelkes emberre van szükségünk ahhoz, hogy a menekültszállót a hét minden napján, a nap 24 órájában működtetni tudják. -mondja a tábor vezetője
              Önkéntesek viszik a recepciót, segítenek az ebédlőben, a tolmácsolásban, ügyelnek a gyerekekre, adják ki a raktárból a napi szükségleteket, szervezik az utazást és az egész szálló összehangolt működését.
            </p>
            <p>
              Megkeresésünkre az élelmiszereken kívül elsősorban gyermekeknek és nőknek kértek higiénés termékeket, vitaminokat, gyógyszereket, amik orvosi rendelvény nélkül adhatók hasmenés, hányás, megfázás, fájdalom és láz kezelésére. Nőknek egészségügyi betéteket, várandós vitaminokat mert állandó jelleggel 2-5 várandós van náluk. Külön kérték, hogy a gyermekeknek, üdítőitalt illetve kekszeket, gyümölcsöket, édességeket vigyünk ha tudunk, mert annak örülnek leginkább.
            </p>
            <p>
              Igyekeztünk minden igényt kielégítően megvásárolni a termékeket, azzal valódi segítséget nyújtani a táborban lakó embereknek . 
            </p>
          </div>
        </div>
      </div>
      {!isOpen ? <p style={{position: 'relative', zIndex: 100, textAlign: 'center', margin: 0, fontSize: '0.75rem', fontWeight: 'bold', color: "#E5B8B8"}}>Tovább olvasok</p> : null}
      <div style={{width: '2rem', height: '2rem', background: "#E5B8B8", borderRadius: '50%', margin: '0 auto'}} className={isOpen ? "more_button less_button" : "more_button"} onClick={() => setOpen(old => !old)}></div>
    </div>
  )
}

export default Activities;
