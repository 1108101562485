import { FC, ReactElement, useRef, useEffect, Dispatch, useState, SetStateAction } from "react";
import ModalElement from "./ModalElement";

const Index: FC<{setActiveElement: Dispatch<SetStateAction<Element[]>>}> = ({setActiveElement}): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const element = ref.current;

    const observer = new IntersectionObserver(([entry]) => {
      entry.isIntersecting ?
        setActiveElement(old => [...old, entry.target])
        :
        setActiveElement(old => old.filter(v => v.id !== entry.target.id))
    }, {rootMargin: '-50%'})

    element && observer.observe(element);

    return () => {
      element && observer.unobserve(element);
    }
  }, [setActiveElement])


  return (
    <>
      <div className="hero_image" ref={ref} id="index">
        <div style={{position: 'absolute', inset: 0, background: 'rgb(70 5 44 / 20%)'}}></div>
        <div className="hero_content">
          <h2>Együtt, közösen a családbarát szülészetekért, szakmánk megbecsüléséért!</h2>
          <h1>Magyar Szülésznők Egyesülete</h1>
          <p>Dolgozzunk együtt a családbarát szülészetek megteremtéséért, a szülésznői szakma megbecsüléséért és szakmai fejlődésünkért!</p>
          <button onClick={() => setModalOpen(true)}>Csatlakozz hozzánk!</button>
        </div>
      </div>
      {modalOpen ? <ModalElement setModalOpen={setModalOpen} /> : null}
    </>
  )
}

export default Index;