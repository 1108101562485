import React, { Dispatch, FC, ReactElement, SetStateAction, useEffect } from "react";

const ModalElement: FC<{setModalOpen: Dispatch<SetStateAction<boolean>>}> = ({setModalOpen}): ReactElement => {

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    
    return (): void => {
      document.body.style.overflow = '';
    }
  }, [])

  const handleModalOpen = (e: React.MouseEvent): void => {
    if (e.currentTarget === e.target) {
      setModalOpen(false)
    }
  }

  return (
    <div style={{backdropFilter: 'blur(3px)', backgroundColor: "#FFF6", inset: 0, position: "fixed", zIndex: 1000, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={handleModalOpen}>
      <div style={{maxWidth: '60rem', backgroundColor: 'white', boxShadow: '1px 3px 20px #0002'}}>
        <h1 style={{color: 'white', backgroundColor: "#E5B8B8", padding: "3rem", margin: 0}}>Csatlakozz hozzánk!</h1>
        <div style={{display: 'flex'}}>
          <div style={{flex: 2}}>
            <img
              src="/baby-baby-feet-bed-325690.jpg"
              alt="Baba lábak"
              style={{objectFit: 'cover', width: '100%', height: '100%'}}
            />
          </div>
          <div style={{flex: 3, padding: "2rem"}}>
            <p>A Magyar Szülésznők Egyesülete azért jött létre, hogy összefogja az állami egészségügyi intézményekben dolgozó, a magánklinikákon tevékenykedő, illetve a praxisban, vagy otthonszüléseknél segédkező szülésznőket, segítse munkájukat, tapasztalatcseréjüket, valamint lehetőséget biztosítson a szakmai fejlődésük támogatására.</p>
            <p>Csatlakozz hozzánk, hogy együtt induljunk el a fejlődés útján, hogy közösen tudjunk tenni a szülésznők elismertségéért, a szakma tiszteletéért és megbecsüléséért.</p>
            <a href="/MSZE_Alapszabaly.pdf" style={{color: '#EBADC9', marginBottom: '1rem', display: 'block'}} target={"_blank"} rel="noreferrer noopener">MSZE Alapszabály</a>
            <a className="msze_button" href="https://docs.google.com/forms/d/e/1FAIpQLSeJTn4cBs8n2tyDsZxNR8OtKlYRWeKjWwo20J39GayGHqn-Tg/viewform" rel="noreferrer" target={"_blank"} onClick={handleModalOpen}>Tovább a csatlakozáshoz</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalElement;