import { Dispatch, FC, SetStateAction, ReactElement, useRef, useEffect, useState } from "react";

const Press: FC<{setActiveElement: Dispatch<SetStateAction<Element[]>>}> = ({setActiveElement}): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState<number>(0);

  useEffect(() => {
    const element = ref.current;

    const observer = new IntersectionObserver(([entry]) => {
      entry.isIntersecting ?
        setActiveElement(old => [...old, entry.target])
        :
        setActiveElement(old => old.filter(v => v.id !== entry.target.id))
    }, {rootMargin: '-50%'})

    element && observer.observe(element);

    return () => {
      element && observer.unobserve(element);
    }
  }, [setActiveElement])

  const circleStyle = {
    borderRadius: '50%',
    width: '1rem',
    height: '1rem',
    background: 'white',
    cursor: 'pointer'
  }

  useEffect(() => {
    const increment = setInterval(() => {
      setActive(old => {
        if (old === 2) {
          return 0;
        }
        return old + 1;
      })
    }, 10000)

    return () => {
      clearInterval(increment)
    }
  }, [])

  return (
    <div id="press" ref={ref}>
      <h1>Megjelenéseink</h1>
      <div style={{background: "linear-gradient(to right,rgba(229,184,184,0.75) ,rgba(235,173,201,0.72) 100%)", paddingTop: '3rem', paddingBottom: '3rem'}}>
        <div className="container press_container" style={{overflow: 'hidden', padding: 0}}>
          <div style={{overflow: 'hidden', background: "White", width: `400%`, display: 'flex', transition: 'all 0.25s ease-in-out', transform: `translateX(-${active / 4 * 100}%)`}}>
          <div className="press_content">
              <div style={{flex: 1, overflow: 'hidden', maxHeight: '20rem', width: '100%'}}>
                <img
                  src="/vizbenszules_cikk.jpg"
                  alt={"A vízben szülés nem hóbort"}
                  style={{objectFit: 'cover', height: '100%', width: '100%'}}
                />
              </div>
              <div style={{flex: 1}}>
                <p>
                  <span style={{fontWeight: 'bold'}}>2019.09.13.</span> / Rákócziné Krämer Ágnes több tucat vízben vajúdásnál, szülésnél volt társa a gyermeküket világra hozó anyáknak. A magyarországi szülésznő, perinatális és laktációs szaktanácsadó egy Csíkszeredában tartott szülészeti konferencián beszélt a vízben szülésről, a víz fájdalomcsillapító hatásairól. D. Balázs Ildikó újságcikke.
                </p>
                <a href="/A vízben szülés nem hóbort - újságcikk - Csíkszereda.pdf" rel="noreferrer noopener" target={"_blank"}>
                  Olvasd el itt
                </a>
              </div>
            </div>
            <div className="press_content">
              <div style={{flex: 1, overflow: 'hidden', maxHeight: '20rem', width: '100%'}}>
                <img
                  src="/nemzetkozi_konferencia.jpg"
                  alt={"Nemzetközi Szülészeti Konferencia"}
                  style={{objectFit: 'cover', height: '100%', width: '100%'}}
                />
              </div>
              <div style={{flex: 1}}>
                <p>
                  <span style={{fontWeight: 'bold'}}>2018.12.06.</span> / Az Egyesületünk által szervezett Nemzetközi Szülészeti Konferenciáról adott hírt az MTVA és több országos média.
                </p>
                <a href="https://www.hirado.hu/tudomany-high-tech/orvostudomany/cikk/2018/12/06/nemzetkozi-szuleszeti-konferenciat-tartanak-budapesten" rel="noreferrer noopener" target={"_blank"}>
                  Hallgasd meg itt
                </a>
              </div>
            </div>
            <div className="press_content">
              <div style={{flex: 1, overflow: 'hidden', maxHeight: '20rem', width: '100%'}}>
                <img
                  src="/karcfm.jpg"
                  alt={"KarcFM vendége az egyesület"}
                  style={{objectFit: 'cover', height: '100%', width: '100%'}}
                /></div>
              <div style={{flex: 1}}>
                <p>
                  <span style={{fontWeight: 'bold'}}>2018.12.11.</span> / KarcFM vendége volt Egyesületünk, ahol a hazai szülészeti ellátás kihívásairól és erősségeiről, valamint a jövőbeli fejlődési lehetőségekről beszélgettünk.
                </p>
                <a href="https://www.mixcloud.com/karcfm/sp%C3%A1jz-2018-12-11-0900-1159-1/" rel="noreferrer noopener" target={"_blank"}>
                  Hallgasd meg itt
                </a>
              </div>
            </div>
            <div className="press_content">
              <div className="press_content_image">
                <img
                  src="/dunatv.jpg"
                  alt={"DunaTV Család-barát műsor"}
                  style={{objectFit: 'cover', height: '100%', width: '100%'}}
                /></div>
              <div style={{flex: 1}}>
                <p>
                  <span style={{fontWeight: 'bold'}}>2019.01.07.</span> / A Duna Televízió Család-barát műsorában beszéltünk a magyarországi családbarát szülészeti ellátás érdekében tett lépésekről, feladatainkról, valamint az Egyesület által szervezett Nemzetközi Szülészeti Konferencia sikeréről.

                </p>
              </div>
            </div>
          </div>
        </div>
        <div style={{display: 'flex', gap: '0.5rem', justifyContent: 'center', marginTop: '1rem'}}>
          {[1, 2, 3, 4].map((v, i) => (
            <span key={i} style={active === i ? {...circleStyle, background: "grey"} : circleStyle} onClick={() => setActive(i)}></span>
          ))}

        </div>
      </div>
    </div>
  )
}

export default Press;