import { FC, ReactElement } from "react";

const Card: FC<{image: string, title: string, description: string}> = ({image, title, description}): ReactElement => {
  return (
    <div className="card_element">
      <div style={{overflow: 'hidden', maxHeight: '16rem', display: 'flex'}}>
        <img
          src={image}
          alt={title}
          style={{objectFit: 'contain', height: '100%', width: '100%'}}
        />
      </div>
      <h3>{title}</h3>
      <p style={{fontSize: '0.8rem', lineHeight: '1.75'}}>{description}</p>
    </div>
  )
}

export default Card;