import { FC, ReactElement, useState } from "react";

type Image = {
  url: string,
  text: string
}

const Images: FC<{images: Image[]}> = ({images}): ReactElement => {
  const [isVisible, setVisible] = useState<number | undefined>();

  return (
    <div className="image_gallery">
      {images.map((image, index) => (
        <div className="image_card" key={index} onMouseOver={() => setVisible(index)} onMouseOut={() => setVisible(undefined)}>
          <img
            src={image.url}
            alt={image.text}
            style={{width: '100%', objectFit: 'cover', objectPosition: 'top center', height: '100%'}}
          />
          {isVisible === index?
            <div className="image_overlay">
              <p>{image.text}</p> 
            </div>
            : null
          }
        </div>
      ))}
    </div>
  )
}

export default Images;