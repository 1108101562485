import { Dispatch, FC, ReactElement, SetStateAction, useEffect, useRef } from "react";

const Awards: FC<{setActiveElement: Dispatch<SetStateAction<Element[]>>}> = ({setActiveElement}): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;

    const observer = new IntersectionObserver(([entry]) => {
      entry.isIntersecting ?
        setActiveElement(old => [...old, entry.target])
        :
        setActiveElement(old => old.filter(v => v.id !== entry.target.id))
    }, {rootMargin: '-50%'})

    element && observer.observe(element);

    return () => {
      element && observer.unobserve(element);
    }
  }, [setActiveElement])
  
  return (
    <div id="awards" className="container" ref={ref}>
      <h1>Díjaink</h1>
      <div>
        <h2 style={{textAlign: 'center'}}>Év Szülésznője Díj</h2>
        <div style={{backgroundColor: '#f7ebf0', color: "#6f6f6f", padding: '2rem 3rem'}}>
          <p>Egyesületünk tagjai már megalakulásunkkor úgy gondolták, fontos, hogy tovább vigyük az korábban az Országos Bábaszövetség által alapított, az Év Bábája díj szellemiségét, így megalapítottuk az Év Szülésznője díjat.</p>
          <p>Szükséges, hogy évről évre elismeréssel adózzunk a szakmának, amely segít, hogy a kismamák biztonságban érezzék magukat, az elhivatott szakembereknek, akik lehetőséget teremtenek a minőségi, családbarát szülészetek megvalósítására.</p>
          <p>Szülésznőnek lenni hivatás, amely mindannyiunk szívügye, és a kismamák köszönete, a családok hálája mellett Egyesületünk azzal is szeretné kifejezni a szülésznők munkája iránt érzett elismerést, hogy évente átadja ezt a megtisztelő díjat.</p>
          <p>2019-ben a díj átadása mellett az év szülésznője egy másik különleges elismerésben is részesült: lehetőséget biztosítunk számára, hogy részt vegyen a 2020-ban Balin megrendezésre kerülő, az International Confederation of Midwives (Nemzetközi Szülésznők Szövetsége) által szervezett konferencián.</p>
        </div>
      </div>
      <div>
        <h2 style={{textAlign: 'center'}}>Szülésznő Nagyköveti Díj</h2>
        <div style={{backgroundColor: '#f7ebf0', color: "#6f6f6f", padding: '2rem 3rem'}}>
          <p>A Magyar Szülésznők Egyesületének vezetősége már a megalakuláskor úgy gondolta, hogy fontos, hogy elismerjük a szülészeti ellátás területéhez kapcsolódó, kiemelkedő szakmai munkát végző egészségügyi szakemberek áldozatos munkáját, akik segítik a szülésznők fejlődését, kiteljesedését, és folyamatosan dolgoznak a szakma megbecsültségéért.</p>
          <p style={{fontWeight: 'bold', lineHeight: 'initial'}}>A Szülésznő Nagykövet Díj 2018-as díjazottjai:</p>
          <p style={{fontWeight: 'bold', lineHeight: 'initial'}}>Dr. Beneda Attila – családpolitikáért felelős helyettes államtitkár</p>
          <p style={{fontWeight: 'bold', lineHeight: 'initial'}}>Dr. Finna Péter – a Mindent a Nőkért Alapítvány alapítója</p>
          <p style={{fontWeight: 'bold', lineHeight: 'initial'}}>Dr. Pap Károly – a Magyar Nőorvos Társaság titkára</p>
          <p style={{fontWeight: 'bold', lineHeight: 'initial'}}>A Szülésznő Nagykövet Díj 2019-es díjazottjai:</p>
          <p style={{fontWeight: 'bold', lineHeight: 'initial'}}>Dr. Ádám Zsolt</p>
        </div>
      </div>
    </div>
  )
}

export default Awards;