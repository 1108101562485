import { FC, ReactElement, useRef, useEffect, Dispatch, SetStateAction } from "react";
import Card from "./Card";

const AboutUs: FC<{setActiveElement: Dispatch<SetStateAction<Element[]>>}> = ({setActiveElement}): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;

    const observer = new IntersectionObserver(([entry]) => {
      entry.isIntersecting ?
        setActiveElement(old => [...old, entry.target])
        :
        setActiveElement(old => old.filter(v => v.id !== entry.target.id))
    }, {rootMargin: '-50%'})

    element && observer.observe(element);

    return () => {
      element && observer.unobserve(element);
    }
  }, [setActiveElement])

  return (
    <>
      <div className="container" style={{position: "relative"}}>
        <img
          src="/site_header-hu-01.jpg"
          alt="Konferencia"
          style={{width: '100%', height: '100%', objectFit: "contain"}}
        />
        <div className="conference_prog_holder">
          <a className="conference_prog reg_button" href="https://docs.google.com/forms/d/1iZPRAcv98kJfnbkdIGRL069-eJexpTs_5pITuOjOcgM/edit?pli=1" target={"_blank"} rel="noreferrer">
            Online regisztráció
          </a>
          <a href="/reszletes_program.pdf" target={"_blank"} referrerPolicy="no-referrer" className="conference_prog">
            Részletes program
          </a>
        </div>
      </div>
      <div className="container" id="about_us" ref={ref}>
        <h1>Rólunk</h1>
        <div className="flex_container">
          <div style={{maxWidth: '100vw'}}>
            <img
              src="/szulesznok_nagylogo.jpg"
              alt="Magyar Szülésznők Egyesülete nagy logó"
              style={{objectFit: 'contain', height: '90%', width: '90%'}}
            />
          </div>
          <div className="boxed_content">
            <h2>Történetünk</h2>
            <p>
              A Magyar Szülésznők Egyesülete azzal a céllal alakult meg 2018-ban, hogy összefogja az állami egészségügyi intézményekben dolgozó, a magánklinikákon tevékenykedő, illetve a praxisban, vagy otthonszüléseknél segédkező szülésznőket, segítse munkájukat, tapasztalatcseréjüket, valamint lehetőséget biztosítson a szakmai fejlődésük támogatására.
            </p>
            <p>
              A magyar szülésznők, bábák többsége magasan képzett szakember, azonban a hazai szülészeti gyakorlatban szerepük sokszor háttérbe szorul. Szakmai, érdekvédelmi és ismeretterjesztő szervezetünk azért dolgozik, hogy az anya és a magzat igényei szerint történő, a háborítatlan szülés gyakorlatát valló szülésznők és szakirányú tevékenységet folytató bábák munkáját segítsük, ezzel együtt a szülészeti ellátásban szükséges szemléletváltás bekövetkezését is támogassuk.
            </p>
            <p>
              A Magyar Szülésznők Egyesülete már megalakulásának évében is tevékenyen részt vett a szakmai közösségek munkájában, a szülészszakma elismertségének növelésében, valamint a szervezet nemzetközi kapcsolatainak és nemzetközi együttműködéseinek kiépítésében.
            </p>
          </div>
        </div>
        <div>
          <h2 style={{textAlign: 'center'}}>Az egyesület tisztségviselői</h2>
          <div className="card_holder">
            <Card image="/eniko.jpg" title="Bihary Enikő – elnök" description="Diplomás szülésznő, a A Szabolcs Szatmár Bereg megyei Egyetemi Oktató Kórház szülésznője, szonográfusa, a Mindent a Nőkért Alapítvány kuratóriumi tagja." />
            <Card image="/patricia.jpg" title="Csomós Patrícia - titkár" description="Diplomás szülésznő, IBCLC laktációs szaktanácsadó, akkreditált babamasszázs oktató, a MindenholJó Bábapraxis tagja." />
            <Card image="/timea.jpg" title="Finna Tímea – gazdasági vezető" description="Diplomás szülésznő, a FINNAMED magánklinika ápolásigazgatója, a Mindent a Nőkért Alapítvány kuratóriumi tagja, a Segítő Kéz Az Európai Ifjúságért Alapítvány kuratóriumának a tagja." />
          </div>
        </div>
        <h2 style={{textAlign: 'center'}}>Céljaink</h2>
        <div className="flex_container">
          <div className="boxed_content">
            <p>Egyesületünk kiemelt célja a családbarát ellátás, a családbarát szülészetek széles körben való elterjesztése, a nemzetközi jó gyakorlatok hazai bevezetése, és a szülészszakmában dolgozók folyamatos továbbképzésének támogatása.</p>
            <p style={{fontWeight: 'bold'}}>Elért eredményeink:</p>
            <ul>
              <li>Hazai és nemzetközi konferenciák szervezése</li>
              <li>Folyamatos szakmai továbbképzések szervezése szülésznők számára</li>
              <li>Közösségi rendezvényeken való bemutatkozás, részvétel</li>
              <li>A Szülésznők Napjának megrendezése</li>
              <li>Szakmai kutatások folytatása, tanulmányok készítése</li>
            </ul>
          </div>
          <div>
            <img
                src="/csecsemo.jpg"
                alt="Boldog baba"
                style={{objectFit: 'cover', width: '100%', height: '100%'}}
              />
          </div>
        </div>
        <div className="flex_container" style={{marginTop: '2rem'}}>
          <div>
            <img
              src="/terhesseg.jpg"
              alt="Terhesség utolsó trimeszter"
              style={{objectFit: 'cover', width: '100%', height: '100%'}}
            />
          </div>
          <div className="boxed_content">
            <p style={{fontWeight: 'bold'}}>További céljaink</p>
            <ul>
              <li>Országos Családbarát Edukációs program elindítása - A Családbarát Szülészetekért</li>
              <li>Nemzetközi kapcsolataink bővítése, együttműködések kialakítása, többek között az Európai Szülésznők Szövetségével</li>
              <li>Szakmai folyóirat indítása</li>
              <li>Hazai és nemzetközi szakmai rendezvények szervezése</li>
              <li>Tudományos kutatói munka</li>
              <li>Ismeretterjesztő kiadványok szerkesztése és publikálása</li>
            </ul>
          </div>
        </div>
        <div>
          <h2 style={{textAlign: 'center'}}>Támogatóink</h2>
          <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <div style={{maxWidth: '800px'}}>
              <img
                src="/NEA_ME_BGA.png"
                alt="támogató partnerek"
                style={{maxWidth: '800px', width: '100%'}}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs;