import { Dispatch, FC, ReactElement, SetStateAction, useEffect, useRef, useState } from "react";
import Images from "./Images";

type Image = {
  url: string,
  text: string
}

const International: FC<{setActiveElement: Dispatch<SetStateAction<Element[]>>}> = ({setActiveElement}): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const [images, setImages] = useState<Image[]>([]);

  useEffect(() => {
    const element = ref.current;

    const observer = new IntersectionObserver(([entry]) => {
      entry.isIntersecting ?
        setActiveElement(old => [...old, entry.target])
        :
        setActiveElement(old => old.filter(v => v.id !== entry.target.id))
    }, {rootMargin: '-50%'})

    element && observer.observe(element);

    return () => {
      element && observer.unobserve(element);
    }
  }, [setActiveElement])

  useEffect(() => {
    const imgArr = [];
    for (let i = 0; i < 15; i++) {
      const image = {
        url: `/international/international_conn_${i + 1}.jpg`,
        text: ""
      }
      imgArr.push(image);
    }
    setImages(imgArr);
  }, [])

  return (
    <div className="container" ref={ref} id="international">
      <h1>Nemzetközi kapcsolatok</h1>
      <p style={{color: '#6f6f6f', textAlign: 'center', maxWidth: '1000px', margin: '0 auto 0.5rem'}}>A Magyar Szülésznők Egyesületének megalakulása óta nagy hangsúlyt fektetünk nemzetközi szakmai kapcsolataink ápolására és új kapcsolatokat kialakítására. Hisszük, hogy minél több gyakorlati tapasztalatra teszünk szert, minél behatóbban ismerjük meg más országok szakmai protokolljait, annál sikeresebben képviselhetjük a kismamák érdekeit, és annál többet tehetünk kollégáinkért is.</p>
      <p style={{color: '#6f6f6f', textAlign: 'center', maxWidth: '1000px', margin: '0 auto 3rem'}}>Rendszeresen szervezünk tanulmányutakat, hogy kutatásaink, tapasztalatcseréink eredményeit szakmai rendezvényeinken első kézből hallhassák szülész kollégáink.</p>
      <Images
        images={[
          ...images,
          {url: "/events/szulesznok_napja_2019.jpg", text: "Dánia, YML képzés / Egyesületünk elnöke 32 ország fiatal szakembereivel együtt vett részt a nemzetközi szervezet továbbképzésén."},
        ]}
      />
    </div>
  )
}

export default International;