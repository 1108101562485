import { FC, ReactElement, useEffect, useRef, useState, Dispatch, SetStateAction } from "react";

const Gdpr: FC<{close: Dispatch<SetStateAction<boolean>>}> = ({close}): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | undefined>(0);

  useEffect(() => {
    const getHeight = () => {
      setHeight(ref.current ? ref.current.clientHeight + 40 : 0);
    }
    getHeight();

    window.addEventListener('resize', getHeight, false);

    return () => {
      window.removeEventListener('resize', getHeight, false);
    }
  }, [])

  return (
    <div style={{overflowY: 'scroll', height: '100vh', position: 'fixed', inset: 0, zIndex: 1000, background: "white"}}>
      <div style={{ height: `${height}px`}}>
        <div style={{position: 'fixed', right: "2rem", top: "2rem"}} className="closer" onClick={() => close(false)}></div>
        <div className="container" ref={ref}>
          <h1>
            GDPR - Adatvédelmi tájékoztató 
          </h1>
          <p>
            Tisztelt honlap látogatók!
          </p>
          <p>
            Tájékoztatjuk az Európai Parlament és a Tanács (EU) 2016/679 rendelete (GDPR) alapján történő személyes adatkezelésről:
          </p>
          <p>
            Az adatkezelő neve és elérhetősége:
          </p>
          <p>
            Magyar Szülésznők Egyesülete. Székhely: 4440 Tiszavasvári Vasvári Pál utca 47.
          </p>
          <p>
            Adatvédelemért felelős neve, elérhetősége:
          </p>
          <p>
            Bihary Enikő elnök
          </p>
          <p>
            E-mail cím: magyarszulesznokegyesulete@gmail.com
          </p>
          <p>
            Az adatkezelés célja:
          </p>
          <p>
            A Magyar Szülésznők Egyesületéhez benyújtott tagsági kérelmekben megjelent személyes adatok kezelése.
          </p>
          <p>
            Az adatkezelés jogalapja:
          </p>
          <p>
            Az Ön  személyes adatai kezelésére az alábbi jogszabályok alapján kerül sor:
          </p>
          <ul>
            <li>
              az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény;
            </li>
            <li>
              az adózás rendjéről szóló 2017. évi CL. törvény (amennyiben számla kiállítására kerül sor);
            </li>
            <li>
              számvitelről szóló 2000. évi C. törvény (amennyiben számla kiállítására kerül sor);
            </li>
          </ul>
          <p>
            A vonatkozó jogszabályok alapján a következő személyes adatokat vesszük fel az Egyesülethez csatlakozáskor:
          </p>
          <ul>
            <li>név, születési név</li>
            <li>születési idő, hely</li>
            <li>anyja születési neve</li>
            <li>lakcím</li>
            <li>telefonszám</li>
            <li>e-mail cím</li>
            <li>működési engedély száma (regisztrációs kártya)</li>
            <li>munkahely adatai</li>
            <li>végzettség</li>
          </ul>
          <p>
            Tájékoztatás az Önt, mint természetes személyt megillető – adatkezeléssel kapcsolatos – jogokról:
          </p>
          <ul>
            <li>tájékoztatást kérhet adatainak kezeléséről;</li>
            <li>kérheti egy vagy több adata kezelésének korlátozását;</li>
            <li>kérheti adatainak átadását vagy továbbítását;</li>
            <li>tájékoztatást kérhet a kezelt adatokról, jogalapról, célról és a kezelés időtartamáról;</li>
            <li>kérheti egy vagy több adatának helyesbítését;</li>
            <li>kérheti adataihoz való hozzáférését, tiltakozhat egy vagy több adata kezelése ellen;</li>
            <li>kérheti – a kötelező adatkezelés kivételével – hogy töröljük egy vagy több adatát;</li>
            <li>jogorvoslati lehetőséggel élhet (a Nemzeti Adatvédelmi és Információszabadság Hatóság és a bíróság felé.).</li>
          </ul>
          <p>
            Nemzeti Adatvédelmi és Információszabadság Hatóság elérhetősége:
          </p>
          <p>
            Székhely: 1125 Budapest Szilágyi Erzsébet fasor 22/c
            <br />
            Postacím:1530 Budapest, Pf.: 5.
            <br />
            Telefonszám: +36 (1) 391-1400
            <br />
            Fax: +36 (1) 391-1410
            <br />
            Központi elektronikus levélcím: ugyfelszolgalat@naih.hu
            <br />
            Honlap: https://naih.hu/
            <br />
            Adatkezeléssel kapcsolatos kérelmét az intézmény vezetőjéhez címzett levélben kérheti, melyet 30 napon belül intézünk.
          </p>
          <p>
            Adattovábbítás harmadik fél részére:
          </p>
          <p>
            Személyes adatai harmadik személy számára – a jogszabályok által előírt kötelező esetek kivételével, illetve az Ön hozzájárulása nélkül – nem továbbítjuk, és nem hozzuk nyilvánosságra!
            <br />
            Bihary Enikő MASZÜL elnök
          </p>
        </div>
      </div>
    </div>
  )
}

export default Gdpr;