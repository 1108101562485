import { FC, ReactElement, useEffect, useState, useRef } from "react";

interface ImageElement {
  url: string,
  text: string
}

const EventCard: FC<{title: string, description: string, images: ImageElement[], further?: string}> = ({title, description, images, further}): ReactElement => {
  const [isVisible, setVisible] = useState<number | undefined>();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [height, setHeight] = useState<number | undefined>(0);
  const par = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const getHeight = () => {
      setHeight(par.current ? par.current.clientHeight + 40 : 0);
    }
    getHeight();

    window.addEventListener('resize', getHeight, false);

    return () => {
      window.removeEventListener('resize', getHeight, false);
    }
  }, [])

  return (
    <>
      <div className="flex_container">
        <div className="boxed_content" style={{position: 'relative'}}>
          <p style={{fontWeight: 'bold'}}>{title}</p>
          <p>{description}</p>
          {further ?
            <>
              {!isOpen ?
                <div style={{position: 'absolute', inset: 0, background: 'linear-gradient(transparent, white)', zIndex: 9}}></div>
                :
                null
              }
              <p>A rendezvénysorozat azon eseményei, melyeken a Magyar Szülésznők Egyesülete személyesen képviseltette magát:</p>
              <div>
                <div style={{height: isOpen ? `${height}px` : 0, transition: 'all 0.25s ease-in-out', overflow: 'hidden' }}>
                  <div ref={par} dangerouslySetInnerHTML={{__html: further}} />
                </div>
                {!isOpen ? <p style={{position: 'relative', zIndex: 100, textAlign: 'center', margin: 0, fontSize: '0.75rem', fontWeight: 'bold', color: "pink"}}>Tovább olvasok</p> : null}
                <div style={{position: 'relative', zIndex: 100, width: '2rem', height: '2rem', background: "pink", borderRadius: '50%', margin: '0 auto'}} className={isOpen ? "more_button less_button" : "more_button"} onClick={() => setOpen(old => !old)}></div>
              </div>
            </>
            : null
          }
        </div>
      </div>
      <div style={{display: "flex", marginBottom: '3rem', gap: '1rem', flexWrap: 'wrap'}}>
        {images.map((image, index) => (
          <div className="event_card" key={index} onMouseOver={() => setVisible(index)} onMouseOut={() => setVisible(undefined)}>
            <img
              src={image.url}
              alt={image.text}
              style={{width: '100%', objectFit: 'cover', objectPosition: 'top center', height: '100%'}}
            />
            {isVisible === index?
              <div style={{zIndex: 2, backgroundColor: "#fff9", textAlign: 'center', position: 'absolute', inset: 0, padding: '1.25rem', display: 'flex', alignItems: 'center'}}>
                <p>{image.text}</p> 
              </div>
              : null
            }
          </div>
        ))}
      </div>
    </>
  )
}

export default EventCard;