import { Dispatch, FC, ReactElement, SetStateAction, useRef, useEffect } from "react";
import EventCard from "./EventCard";

const Events: FC<{setActiveElement: Dispatch<SetStateAction<Element[]>>}> = ({setActiveElement}): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;

    const observer = new IntersectionObserver(([entry]) => {
      entry.isIntersecting ?
        setActiveElement(old => [...old, entry.target])
        :
        setActiveElement(old => old.filter(v => v.id !== entry.target.id))
    }, {rootMargin: '-50%'})

    element && observer.observe(element);

    return () => {
      element && observer.unobserve(element);
    }
  }, [setActiveElement])

  return (
    <div ref={ref} className="container" id="events">
      <h1>Rendezvényeink</h1>
      <p style={{color: '#6f6f6f', textAlign: 'center', maxWidth: '1000px', margin: '0 auto 3rem'}}>Országos és nemzetközi szakmai rendezvényeinken a szülészszakmában dolgozók számára biztosítunk lehetőséget, hogy megismerjék a legújabb szakmai trendeket, technológiai újításokat, valamint azokat a nemzetközi gyakorlatokat, amelyeket napi munkájukba beépítve egy biztonságosabb, családbarátabb szülészeti szolgáltatást tudnak nyújtani az édesanyák számára.</p>
      <EventCard
        title="2022. március 11. - 2022. november 4. / Hugonnai Vilma halálának 100. évfordulója / UNESCO-val közös megemlékezés"
        description="Az UNESCO Magyar Nemzeti Bizottság 2020-ban terjesztette elő Hugonnai Vilma - az első magyar orvosnő - halálának 100. évfordulóját az UNESCO-val való közös megemlékezésre. Az UNESCO közgyűlésének 2021. novemberi ülésszaka az előterjesztést elfogadta. Az évfordulót különlegessé tette, hogy 2022-ben egyben Hugonnai Vilma születésének 175. évfordulóját is ünnepelhettük. Az UNESCO Magyar Nemzeti Bizottság Nevelésügyi Szakbizottsága elnökének felkérésére a Magyar Női Unió elvállalta a „Hugonnai Vilma (1847-1922) első magyar orvosnő halálának 100. évfordulója UNESCO-val közös megemlékezés” rendezvénysorozat koordináló szerepét. Az Emberi Erőforrások Minisztériuma, a Honvédelmi Minisztérium, a Külgazdasági és Külügyminisztérium, az UNESCO Magyar Nemzeti Bizottság, a Kopp Mária Intézet (KINCS), a Semmelweis Orvostörténeti Múzeum, Könyvtár és Levéltár, az Országos Kórházi Főigazgatóság, a Magyar Védőnők Szakmai Szövetsége, valamint a Magyar Szülésznők Egyesülete képviselői az emlékév mellé álltak, és a március 11-ei nyitó rendezvényen Együttműködési megállapodást írtak alá."
        images={[
          {url: "/events/hugonnai.jpg", text: "Hugonnai Vilma halálának 100. évfordulója / UNESCO-val közös megemlékezés"},
          {url: "/events/hugonnai_ter.jpg", text: "Hugonnai Vilma halálának 100. évfordulója / UNESCO-val közös megemlékezés"},
          {url: "/events/hugonnai_koszoruzas.jpg", text: "Hugonnai Vilma halálának 100. évfordulója / UNESCO-val közös megemlékezés"},
          {url: "/events/hugonnai_koszoruzas_2.jpg", text: "Hugonnai Vilma halálának 100. évfordulója / UNESCO-val közös megemlékezés"}
        ]}
        further={`<h3>2022. március 11. Nyitó esemény</h3>
        <p>„…az én kardom a tudomány, az én pajzsom a munka…” Dr Hugonnai Vilma
          A Magyar Szülésznők Egyesületét Dr Rákócziné Krämer Ágnes alelnök képviselte március 11.-én egy sajtótájékoztatóval egybekötött ünnepségen a Semmelweis Orvostudományi Múzeumban.  A megnyitó címe: „Hugonnai Vilma első magyar orvosnő szerepe a családok és nők egészségének megőrzésében” címet viselte. Az ünnepség egy olyan eseménysorozat megnyitó része volt, mely a 2022-es esztendőben zajlott. A rendezvénysorozattal Hugonnai Vilma bátor, kitartó gyógyító tevékenysége, lelki ereje, újító és kezdeményező szemléletmódja mellett a nők társadalmi szerepét és értékként követendő szellemiségét állítottuk példaként a következő generációk számára.
        Hugonnai Vilma  a Zürichi Egyetemen végezte el az orvosi képzését. Magyarországra hazatérve svájci diplomáját nem ismerték el, ezért orvosként nem praktizálhatott. Elvégezte a szülésznő képzőt és közel két évtizedig így segítette a hozzá fordulókat. A gyógyítás motiválta, szívügyévé vált a női egyenjogúság. Sosem szakadt el az orvostudománytól: ápolt és tanított. A nők számára is kedvező iskolareform mellett foglalt állást, képviselte a nők oktatáshoz való jogának ügyét.
          Az ünnepségen aláírásra került egy együttműködési megállapodás, melyben a MASZÜL is képviseltette magát. A Munkacsoport tagjai a rendezvénysorozat valamennyi eseményén annak céljaival, kommunikációs üzeneteivel, értékrendjével és szellemiségével teljes összhangban jártak el. Emellett az egyes – saját szervezésű – eseményekről kölcsönösen tájékoztatták egymást, illetve saját kommunikációs felületeiken szinergiában erősítették a fenti eseménysorozatot.
        </p><h3>2022. március 25. Koszorúzás a Fiumei úti sírkertben Hugonnai Vilma halálának 100. évfordulóján</h3><p>
        Az első magyar orvosnő Fiumei úti sírkertben található sírjánál
        Móczár Gábor, a Nemzeti Örökség Intézetének főigazgatója kiemelte, bár halálakor már több száz orvosnő praktizált az országban, az orvosi szaklapok egyetlen nekrológgal sem emlékeztek meg az első magyar orvosnőről. Így nekünk, száz évvel később, nemcsak azt kell tisztelnünk benne, hogy ő az első magyar orvosnő, hanem azt is, hogy mekkora munkával és ehhez képest milyen kevés elismerés mellett érte ezt el. „100 éves adósságot törlesztünk ezzel a megemlékezéssel” – ezt hangsúlyozta Réthelyi Miklós, az az UNESCO Magyar Nemzeti Bizottság elnöke, a Nemzeti Emlékhely és Kegyeleti Bizottság tagja is emlékbeszédében. Az emlékbeszédek után a kegyelet virágait helyeztük el az orvosnő sírjánál.
        </p><h3>
        2022 május 31. „Az én 21. századom Mit üzen nekem Hugonnai Vilma?” - kerekasztal-
        beszélgetés (SCRUTON Belváros) </h3><p>
        A SCRUTON, mint kávéház és közösségi tér kitűnő helyszín, a pódium-beszélgetés pedig a saját történeteken, egyéni érintettségen, valamint többfajta nézőponton keresztül közvetlen kapcsolatot teremtett a résztvevők, Hugonnai Vilma és a történelmi-kulturális háttér között.
        Egyfajta tudománytörténeti-kulturális utazás volt ez, ahol több mint 150 év távlatából kötelességek és lehetőségek, elvárások és hivatástudat, eszmék és vágyak, lehetőségek és szükségszerűségek ütköztek. Emellett arra a  kérdésre is kerestük a választ, hogy mennyit változott a világ, és valójában mit is köszönhetünk ennek a tragikus sorsú, elszánt asszonynak. A kerekasztal beszélgetésben részt vett Bizek Emőke zeneszerző (kinek műveiből hallgathattunk is néhányat) aki rokonságban van Dr Hugonnai Vilmával. Különleges személyes kapocs még az is, hogy Emőke első gyermekének születésénél Rákóczi Ágnes volt a szülésznő.
        </p><h3>
        2022. október 6.  Szülésznő hallgatók versenye</h3><p>
        Dr. Hugonnai Vilma születésének 175. évfordulója tiszteletére a Debreceni Egyetem Egészségtudományi Kar és a Magyar Védőnők Szakmai Szövetsége támogatásával hagyományteremtő céllal 2022-ben meghirdette a szülésznő hallgatók első szakmai versenyét, melyre Nyíregyházán került sor.
         „A mai versenyünk mottója a szülésznői hivatás történetéről szól. Eköré csoportosulnak a kérdések. Lesz tesztkitöltés, orvoslástörténet, illetve különböző ügyességi gyakorlatok” – mondta Dr Rákóczi Ildikó az Egészségtudományi Kar Ápolási és Szülésznői Tanszékének vezetője. A rendezvény zsűrijében a Magyar Szülésznők Egyesületét Dr Rákócziné Krämer Ágnes képviselte.
        „Büszkén mondhatjuk, hogy Magyarországon már jelentős ideje a szülésznők is felsőfokú, főiskolai szintű végzettséget szereznek, ami azt jelenti, hogy komoly feladatokat látnak el a szülész orvosok mellett, a gyerekágyas és várandós osztályokon, illetve most már lehetőség van a várandós gondozásba is bekapcsolódniuk” – nyilatkozta Várfalvi Marianna, az Országos Kórházi Főigazgatóság szakmai tanácsadója aki egyben a Magyar Védőnők Szakmai Szövetségének elnöke is.
        </p><h3>2022  november 4. Koszorúzás az I. világháborús orvos-hősök emlékműnél </h3><p>
        A Semmelweis Egyetem Női Klinikája előtt lévő Hugonnai Vilma téren a Nemzeti Gyásznapon gyűltek össze az ünneplők. Dr Hugonnai Vilmára, az első magyar orvosnőre emlékezve a MASZÜL koszorút helyezett el a Magyar Orvos Hősi Halottak Emlékművénél tartott megemlékezésen.
        </p>`}
      />
      <EventCard
        title="2019. május 06. / Magyar Szülésznők Napja"
        description="A Magyar Szülésznők Egyesülete és az Egszülős Központ közösen ünnepelte a Magyar Szülésznők Napját, és a szakmát, amely segít, hogy a kismamák biztonságban érezzék magukat, a szakmát, amely lehetőséget teremt a minőségi, családbarát szülészetek megvalósítására, a hivatást, amely mindannyiunk szívügye. A rendezvény keretében került sor Az év szülésznője díj átadására is."
        images={[
          {url: "/events/szulesznok_napja_2019.jpg", text: "Magyar Szülésznők napja 2019. május 6."},
          {url: "/events/szulesznok_napja_2019_v2.jpg", text: "Magyar Szülésznők napja 2019. május 6."},
          {url: "/events/szulesznok_napja_2019_v3.jpg", text: "Magyar Szülésznők napja 2019. május 6."},
          {url: "/events/szulesznok_napja_2019_v4.jpg", text: "Magyar Szülésznők napja 2019. május 6."}
        ]}
      />
      <EventCard
        title="2018. december 6-8. / Nemzetközi Szülészeti Konferencia"
        description="Egyesületünk társszervezőként vett részt az első budapesti Nemzetközi Szülészeti Konferencia megrendezésében, ahol olyan neves előadókkal találkozhattak a szülésszakmában dolgozók, mint Dr. Sally Pairman, a Szülésznők Nemzetközi Szövetsége (ICM) elnöke, Ibu Robin Lim “CNN” Hero of the Year díjas bába, és Dr. Michel Odent francia orvos, kutató."
        images={[
          {url: "/events/nemzetkozi_szulesz_konf.jpg", text: "Nemzetközi Szülészeti Konferencia"},
          {url: "/events/nemzetkozi_szulesz_konf_v2.jpg", text: "Nemzetközi Szülészeti Konferencia"},
          {url: "/events/nemzetkozi_szulesz_konf_v3.jpg", text: "Nemzetközi Szülészeti Konferencia"},
          {url: "/events/nemzetkozi_szulesz_konf_v4.jpg", text: "Nemzetközi Szülészeti Konferencia"}
        ]}
      />
      <EventCard
        title="2018. szeptember 13-15. / II. Konferencia a Várandósgondozásért és a Természetes Szülésért"
        description="Magyarországon jelenleg a szülészeti ellátásban változásra van szükség, ezért 2018. szeptemberében ismét megrendezésre került a Magyar Szülésznők Egyesülete és a Mindent a Nőkért Alapítvány szervezésében a II. Konferencia a Várandósgondozásért és a Természetes Szülésért."
        images={[
          {url: "/events/konf_varandosgondozas.jpg", text: "Incze Tímea Nemzetközi kapcsolatok erősítésének törekvéseiről és fontosságáról beszél. 2018. Bodrogkisfalud"},
          {url: "/events/konf_varandosgondozas_v2.jpg", text: ""},
          {url: "/events/konf_varandosgondozas_v3.jpg", text: "Egyesületünk titkára ( Csomós Patricia) és a Szabolcs-Szatmár-Bereg megyei kórház Szülészet-Nőgyógyászati osztályának osztályvezető főorvosa (Dr. Pap Károly)"},
          {url: "/events/konf_varandosgondozas_v4.jpg", text: "A konferencia zárása után többen maradtak kötetlen beszélgetésre ☺"}
        ]}
      />
    </div>
  )
}

export default Events;