import { FC, ReactElement, useState, useEffect } from 'react';
import './App.css';
import AboutUs from './components/AboutUs';
import Activities from './components/Activities';
import Awards from './components/Awards';
import Contact from './components/Contact';
import Events from './components/Events';
import Gdpr from './components/Gdpr';
import Header from './components/Header';
import Index from './components/Index';
import International from './components/International';
import Press from './components/Press';

const App: FC = (): ReactElement => {
  const [activeElement, setActiveElement] = useState<Element[]>([]);
  const [isGdpr, setGdpr] = useState<boolean>(false);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    if (params.get('gdpr') === 'true') {
      setGdpr(true);
    } 
  }, [])

  useEffect(() => {
    if (isGdpr) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isGdpr])

  return (
    <div className="App" style={{position: 'relative'}}>
      {isGdpr ? <Gdpr close={setGdpr} /> : null}
      <Header activeElement={activeElement} />
      <Index setActiveElement={setActiveElement} />
      <AboutUs setActiveElement={setActiveElement} />
      <Events setActiveElement={setActiveElement} />
      <Awards setActiveElement={setActiveElement} />
      <Activities setActiveElement={setActiveElement} />
      <International setActiveElement={setActiveElement} />
      <Press setActiveElement={setActiveElement} />
      <Contact setActiveElement={setActiveElement} open={setGdpr} />
    </div>
  );
}

export default App;
