import { Dispatch, FC, ReactElement, SetStateAction, useEffect, useRef } from "react";
import FacebookLogo from "./FacebookLogo";
import InstaLogo from "./InstaLogo";

const Contact: FC<{setActiveElement: Dispatch<SetStateAction<Element[]>>, open: Dispatch<SetStateAction<boolean>>}> = ({setActiveElement, open}): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;

    const observer = new IntersectionObserver(([entry]) => {
      entry.isIntersecting ?
        setActiveElement(old => [...old, entry.target])
        :
        setActiveElement(old => old.filter(v => v.id !== entry.target.id))
    }, {rootMargin: '-50%'})

    element && observer.observe(element);

    return () => {
      element && observer.unobserve(element);
    }
  }, [setActiveElement])

  return (
    <>
      <div className="container" ref={ref} id="contact">
        <h1>Kapcsolat</h1>
        <div style={{display: 'flex', justifyContent: 'center', padding: '2rem', flexDirection: 'column', color: "#6f6f6f", textAlign: 'center'}}>
          <p>Kedves Kollégák!</p>
          <p>
            Arra szeretnénk bátorítani titeket, hogy osszátok meg velünk gondolataitokat, véleményeiteket! Hiszen Egyesületünk azért jött létre, hogy együtt dolgozzunk szakmánk megbecsüléséért, szakmai fejlődésünkért, és ezt a munkát közösen tudjuk sikerre vinni. Így akár problémáitokkal, akár tapasztalataitokkal vagy örömeitekkel fordultok hozzánk, minden levelet hálásan fogadunk!
          </p>
        </div>
        <div style={{marginTop: '3rem', background: 'linear-gradient(to right,rgba(229,184,184,0.75) ,rgba(235,173,201,0.72) 100%)', textAlign: 'center', paddingTop: '2rem', paddingBottom: '2rem', color: 'white'}}>
          <p className="strong">Magyar Szülésznők Egyesülete</p>
          <p className="strong">Címünk:</p>
          <p style={{color: "#6f6f6f"}}>4440 Tiszavasvári, Vasvári Pál utca 47.</p>
          <p className="strong">E-mail címünk:</p>
          <p style={{color: "#6f6f6f"}}>magyarszulesznokegyesulete@gmail.com</p>
        </div>
        <div style={{marginTop: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <img
            src="/logo-10.png"
            alt="Magyar Szülésznők Egyesülete logó"
            style={{height: '10rem'}}
          />
        </div>
      </div>

      <footer style={{backgroundColor: "#EBADC9", height: '100%'}}>
        <div style={{backgroundColor: "#EBADC9", padding: '2rem', display: 'flex', justifyContent: 'center', gap: '2rem', flexWrap: 'wrap'}}>
          <a href="https://www.facebook.com/MindentANokertAlapitvany/" target={"_blank"} rel="noreferrer"><FacebookLogo /></a>
          <a href="https://www.instagram.com/mindentanokert/" target={"_blank"} rel="noreferrer"><InstaLogo /></a>
        </div>
        <div style={{cursor: 'pointer', textAlign: 'center', paddingBottom: '1rem', color: 'white'}} onClick={() => open(true)}>
          GDPR adatkezelés
        </div>
      </footer>
    </>
  )
}

export default Contact;